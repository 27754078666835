<template>
  <div class="chat-left-sidebar rounded-0-- h-full">
    <!-- UserProfile (Me) and Search -->
    <div class="d-flex align-center pa-4">
      <v-badge
        bottom
        overlap
        dot
        bordered
        :color="resolveUserStatus(chatUserProfile.status).color"
        offset-x="11"
        offset-y="11"
        class="me-3 user-status-badge"
      >
        <!-- Open User Profile sidebar -->
        <!-- @click="$emit('open-chat-user-profile-sidebar', true); $vuetify.breakpoint.xsOnly && $emit('update:is-sidebar-active', false)" -->
        <v-avatar
          size="38"
          class="cursor-pointer"
          :class="`v-avatar-light-bg ${resolveUserStatus(chatUserProfile.status).color}--text`"
          @click.stop="$emit('open-chat-user-profile-sidebar', true)"
        >
          <v-img
            v-if="chatUserProfile.avatar"
            :src="chatUserProfile.avatar"
          ></v-img>
          <span v-else>{{ avatarText(chatUserProfile.fullName) }}</span>
        </v-avatar>
      </v-badge>
      <v-text-field
        v-model="searchQuery"
        placeholder="search..."
        hide-details
        outlined
        rounded
        dense
        class="chat-search"
        :prepend-inner-icon="icons.mdiMagnify"
      ></v-text-field>
      <v-icon
        class="d-inline-flex d-sm-none ms-1"
        @click="$emit('update:is-sidebar-active', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>

    <!-- Loading indicator -->
    <!-- <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="position-absolute"
    ></v-progress-linear> -->
    <v-divider></v-divider>

    <perfect-scrollbar
      :options="perfectScrollbarOptions"
      class="ps-chat-left-sidebar py-3 px-3"
    >
      <!-- Chats -->
      <div class="d-flex align-center font-weight-medium text-xl primary--text ms-3">
        <!-- :to="menuItemChat.route" (No navegamos porque ya estamos aquí)-->
        <v-list-item
          link
          class="pl-0"
        >
          <v-btn
            icon
            :outlined="false"
            color="primary"
          >
            <v-icon size="22">
              {{ menuItemChat.icon }}
            </v-icon>
          </v-btn>
          <span class="ml-2">{{ menuItemChat.title }}</span>
        </v-list-item>
        <!-- <v-btn
          icon
          small
          class="me-2"
          @click="/*fetchCampaigns*/"
        >
          <v-icon size="21">
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn> -->
      </div>
      <ChatRoomCard
        v-for="chatRoomUser in filteredChatRoomUsers"
        :key="`chat-${chatRoomUser.id}`"
        :contact="chatRoomUser.contact"
        :chat-room-user="chatRoomUser"
        is-chat-contact
        :is-active="activeChatContactId === chatRoomUser.contact.id"
        :class="{'bg-gradient-primary active-chat-contact': activeChatContactId === chatRoomUser.contact.id}"
        @click="$emit('open-chat', chatRoomUser.contact)"
      />

      <!-- Contacts -->
      <div class="d-flex align-center font-weight-medium text-xl primary--text mt-7 ms-3">
        <v-list-item
          link
          :to="menuItemContact.route"
          class="pl-0"
        >
          <v-btn
            icon
            :outlined="false"
            color="primary"
          >
            <v-icon size="22">
              {{ menuItemContact.icon }}
            </v-icon>
          </v-btn>
          <span class="ml-2">{{ menuItemContact.title }}</span>
        </v-list-item>
      </div>
      <ChatRoomCard
        v-for="contact in filteredContacts"
        :key="contact.id"
        :contact="contact"
        @click="$emit('open-chat', contact)"
      />
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mdiMagnify, mdiClose, mdiReload } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

// Components
import ChatRoomCard from './ChatRoomCard.vue'
import ChatContactCard from './ChatContactCard.vue'

// Services
import useChatHandler from '../useChatHandler'
import useRouterPath from '@/router/useRouterPath'

export default {
  components: {
    PerfectScrollbar,
    ChatRoomCard,
    // eslint-disable-next-line vue/no-unused-components
    ChatContactCard,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },

    // chatUserProfile: {
    //   type: Object,
    //   required: false, // Lazy loading
    //   default: null,
    // },
    chatUserProfile: {
      type: Object,
      required: true,
    },
    chatRoomUsers: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    activeChatContactId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { resolveUserStatus } = useChatHandler()

    const { menuItemChat, menuItemContact } = useRouterPath()

    // Search Query
    const searchQuery = ref('')

    // debugger

    const searchFilterFunction = userContact => userContact.firstName.toLowerCase().includes(searchQuery.value.toLowerCase())
    const filteredChatRoomUsers = computed(() => props.chatRoomUsers.filter(chatConnection => searchFilterFunction(chatConnection.contact.userContact)))
    const filteredContacts = computed(() => props.contacts.filter(contact => searchFilterFunction(contact.userContact)))

    // const filteredChatRoomUsers = computed(() => props.chatRoomUsers.filter(chatConnection => chatConnection.contact.userContact?.firstName.toLowerCase().includes(searchQuery.value.toLowerCase())))
    // const filteredContacts = computed(() => props.contacts.filter(connection => connection.userContact?.firstName.toLowerCase().includes(searchQuery.value.toLowerCase())))

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    return {
      menuItemChat,
      menuItemContact,

      // Use Chat
      resolveUserStatus,

      // Search
      searchQuery,
      filteredChatRoomUsers,
      filteredContacts,

      // Perfect Scrollbar options
      perfectScrollbarOptions,

      // Filter
      avatarText,

      // Icons
      icons: {
        mdiMagnify,
        mdiClose,
        mdiReload,
      },
    }
  },
}
</script>
