import { getVuetify } from '@core/utils'
import { computed, ref, watch } from '@vue/composition-api'

// >>> SFR Copia de ui.js -> Variante para 2 v-navigation-drawer
export const useResponsiveLeftSidebar = (sidebarWidth, sidebarWidthRight) => {
  const isLeftSidebarOpen = ref(true)
  const isRightSidebarOpen = ref(true)
  const $vuetify = getVuetify()

  const setInitialValue = () => {
    isLeftSidebarOpen.value = $vuetify.breakpoint.smAndUp
    isRightSidebarOpen.value = $vuetify.breakpoint.mdAndUp
  }

  // Set the initial value of sidebar
  setInitialValue()

  watch(
    () => $vuetify.breakpoint.name,
    () => {
      setInitialValue()
    },
  )

  const contentStyles = computed(() => {
    const styles = {}

    styles.marginLeft = isLeftSidebarOpen.value && $vuetify.breakpoint.smAndUp ? `${sidebarWidth}px` : null
    styles.marginRight = isRightSidebarOpen.value && $vuetify.breakpoint.mdAndUp ? `${sidebarWidthRight}px` : null

    return styles
  })

  return {
    isLeftSidebarOpen,
    isRightSidebarOpen,
    contentStyles,
  }
}

export const _ = {}
