// enum ChatRoomType {
//     Single = 'single',
//     Group = 'group',
// }

// // getUserInfo(User)
// interface UserInfo {
//     userId: String;
//     avatar: String;
//     fullName: String;
//     companyRole: String;
// }

// // getChatUserProfile(User)
// interface ChatUserProfile {
//     userId: String;
//     userInfo: UserInfo;
//     about: String;
//     status: String;
//     settings: Array; // isNotificationsOn: Boolean,
// }

// Table:
// interface ChatRoom {
//     id: String;
//     chatRoomType: ChatRoomType;
//     chats: [ChatRoomMessage];
//     users: [ChatUserProfile];
// }

// Table:
// interface ChatRoomMessage {
//     id: String;
//     message: String;
//     time: String;
//     sender: ChatUserProfile;
// }

// Table:
// interface ChatRoomUser {
//     id: String;
//     chatRoom: ChatRoom;
//     user: User;

//     chatRoomId: String;
//     userInfo: UserInfo(user);
//     contactId: String; // No
//     unseenMsgs: Number;
//     lastMessage: ChatRoomMessage;
// }

// // getProfileUser(User)
// interface ProfileUser {
//     userId: String;
//     avatar: String;
//     fullName: String;
//     companyRole: String;
//     about: String;
//     status: String;
//     isNotificationsOn: Boolean;  // Settings
// }

// // getProfileContact(Contact(User))
// interface ProfileContact {
//     userId: String;
//     avatar: String;
//     fullName: String;
//     companyRole: String;
//     about: String;
//     status: String;
// }

// Table: User1 and User2 connects with CampaignInbound nº 123456
// interface Connection {
//     id: String;
//     contact1: Contact;
//     contact2: Contact;

//     docType: String;
//     docId: String;
// }

// Table: Una connexión genera un contacto bidireccional
//  User1 -> User2 [View-A] Vista del usuario 1
//  User1 <- User2 [View-B] Vista del usuario 2
// class ContactInterface {
//     id: String;
//     user: User;    // Ownner
//     contact: User; // Contact

//     status: String;
//     language: String;
//     tags: [String];
// }
//
// interface Contact {
//     id: String;
//     user: getProfileUser(user);
//     contact: ProfileContact(userContact);
//     chatUserProfileContact: ChatUserProfile(contactId);
//
//     status: String;     // ENUM( new | interesant | irrelevant )
//     language: String;   // en
//     tags: [String];     // [ ISO9001, COVID-19 ]
// }

// Vista: Contacto + ChatRoomUser como una propiedad más de contact
// interface ChatContact {
//     contact: Contact + ChatRoomUser;
// }

import axios from '@axios'
import store from '@/store'

// Chat Router list
// GET    /chat/chats-and-contacts Retrieves a chatsContacts and Contacts.
// GET    /chat/chat/{id}          Retrieves a ChatsContact.
// POST   /chat/send-message       Creates a ChatRoomMessage and ChatRoom if new.

// GET    /chat/profile-user       Retrieves a ProfileUser(AuthUser)
// PUT    /chat/profile-user       Change a ProfileUser(AuthUser)
const routeChats = '/chat'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ChatRoom extends BaseApiService { constructor() { super(axios)}
class ChatRoom {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  //   API_BASE = this.baseUrl

  // Request:  userId
  // Response:
  //    chatContact:  [ChatContact],
  //    contacts      [Contact]
  //    chatUserProfile: getChatUserProfile(User)
  //
  //   fetchChatRoomsAndConnections(queryParams = {}) {
  fetchChatsAndContacts(queryParams = {}) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeChats}/chats-and-contacts`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Request:  chatRoomId/chatRoomUserId
  // Response: chatConnection
  fetchChat(chatRoomId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeChats}/chats/${chatRoomId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Request:  contactId
  // Response: ChatRoomUser
  fetchChatByContactId(contactId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeChats}/chat-by-contact/${contactId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /*
   * Chat
   */
  // Si no existe el ChatRoom, la creamos
  // Request:  contactId, message
  // Response:
  //    chatMessageNew: ChatRoomMessage
  //    activeChatRoomUserCreated: Boolean
  //    chatConnection: ChatConnection
  //    isNewChat
  sendChatMessage(payload) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeChats}/chats/send-message`, { ...payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Limpieza de mensajes: Solo para el usuario que lo solicita
  // Response: ChatRoomUser
  clearChatRoomUser(chatRoomUserId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeChats}/chats/cleaner`, { chatRoomUserId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /*
   * ProfileUser
   */
  // Request:  <CurrentUser by Authentification>
  // Response: ProfileUser
  fetchChatUserProfile() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeChats}/user-profile`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Request:  newStatus: enum(online | not_disturb | away | no_disturb)
  // Response: ProfileUser
  updateChatUserProfileStatus(newStatus) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeChats}/user-profile/status`, { status: newStatus })
        .then(response => {
          // Actualizamos el estado manualmente porque no tenemos todo el userData
          const { chatUserProfile } = response.data
          if (chatUserProfile) {
            // Update User Status
            // if (store.state.user && store.state.user.userData) {
            //  store.state.user.userData.status = chatUserProfile.status
            // }
            store.commit('user/CHANGE_STATUS', chatUserProfile.status)
          }
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  // Request:  newAbout: String
  // Response: ProfileUser
  updateChatUserProfileAbout(newAbout) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeChats}/user-profile/about`, { about: newAbout })
        .then(response => {
          // Actualizamos el estado manualmente porque no tenemos todo el userData
          const { chatUserProfile } = response.data
          if (chatUserProfile) {
            if (store.state.user && store.state.user.userData) {
              store.state.user.userData.about = chatUserProfile.about
            }
          }
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  /*
   * ProfileUser => Ahora usamos useContact
   */
  //   // Request:  payload = { status: newStatus}
  //   // Response: Contact
  //   updateChatContactProfileStatus(contactId, payload) {
  //     return new Promise((resolve, reject) => {
  //       this.axiosIns
  //         .put(`${this.API_BASE}${routeChats}/contact-profile/${contactId}`, { ...payload })
  //         .then(response => resolve(response))
  //         .catch(error => reject(error))
  //     })
  //   }
}

const useChatRoom = new ChatRoom()
export default useChatRoom
