<template>
  <v-card class="h-full user-profile-sidebar-content mb-4">
    <div class="">
      <!-- Close Button -->
      <div
        class="pt-2 me-2"
        :class="$vuetify.rtl ? 'text-left' : 'text-right'"
      >
        <!-- Send both events (is-sidebar-active.sync & onClose()) -->
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-active', false); $emit('on-close')"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <!-- User Avatar + Name + Role -->
      <div class="text-center px-4 pt-4">
        <v-badge
          bottom
          overlap
          bordered
          :color="resolveUserStatus(chatUserProfileLocal.status).color"
          offset-x="24"
          offset-y="18"
          class="user-status-badge-lg mb-5"
        >
          <v-avatar
            size="80"
            :class="`v-avatar-light-bg ${resolveUserStatus(chatUserProfileLocal.status).color}--text`"
          >
            <v-img
              v-if="chatUserProfileLocal.avatar"
              :src="chatUserProfileLocal.avatar"
            ></v-img>
            <span
              v-else
              class="text-3xl"
            >{{ avatarText(chatUserProfileLocal.fullName) }}</span>
          </v-avatar>
        </v-badge>
        <h2 class="mb-1 font-weight-medium text-base">
          {{ chatUserProfileLocal.fullName }}
        </h2>
        <span class="text-capitalize">
          {{ chatUserProfileLocal.companyRole }}
        </span>
      </div>

      <!-- User Data -->
      <!-- <perfect-scrollbar
        class="ps-chat-user-profile-sidebar-content pb-6 px-4"
        :options="perfectScrollbarOptions"
      > -->
      <div class="pt-2 pb-6 px-4">
        <!-- About -->
        <div>
          <span
            for="textarea-user-about"
            class="text-xs text--disabled"
          >ABOUT
          </span>
          <v-textarea
            id="textarea-user-about"
            v-model="chatUserProfileLocal.about"
            outlined
            auto-grow
            class="mt-2"
            rows="4"
            deta
            hide-details="auto"
            @change="onChangeUserAbout(chatUserProfileLocal.about)"
          ></v-textarea>
        </div>

        <!-- Contact status -->
        <div>
          <v-radio-group
            v-model="chatUserProfileLocal.status"
            class="mt-2"
            column
            @change="onChangeUserStatus(chatUserProfileLocal.status)"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <v-icon class="me-2">
                  {{ icons.mdiAccountCheck }}
                </v-icon>
                <h3>Profile <strong>status</strong></h3>
              </div>
            </template>
            <v-radio
              v-for="option in userStatusOptions"
              :key="option.value"
              :label="option.text"
              :color="option.color"
              :value="option.value"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Settings -->
        <div>
          <span
            class="text-xs text--disabled"
          >
            SETTINGS
          </span>
          <div class="d-flex align-center mb-3">
            <v-icon class="me-2">
              {{ icons.mdiBellOutline }}
            </v-icon>
            <span>Chat Notification</span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiClose, mdiBellOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import { avatarText } from '@core/utils/filter'

// Services
import useChat from '@/services/api/modules/chat'
import useChatHandler from '../useChatHandler'

export default {
  components: {
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    chatUserProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserStatus, userStatusOptions, sendChatNotification } = useChatHandler()

    // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
    // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
    const chatUserProfileLocal = ref(JSON.parse(JSON.stringify(props.chatUserProfile)))
    watch(() => props.chatUserProfile, () => {
      chatUserProfileLocal.value = JSON.parse(JSON.stringify(props.chatUserProfile))
    })

    // ------------------------------------------------
    // chatUserProfileLocal Actions
    // ------------------------------------------------
    const onChangeUserAbout = aboutData => {
      // dataToUpdate = { about: aboutData }
      useChat.updateChatUserProfileAbout(aboutData)
        .then(response => {
          const { chatUserProfile } = response.data
          if (chatUserProfile) {
            chatUserProfileLocal.value = chatUserProfile
          }
          sendChatNotification('Updated !!', 'success')
        })
        .catch(error => {
          // Roolback ui
          chatUserProfileLocal.value.about = props.chatUserProfile.about

          if (error.response && error.response.data && error.response.data.error) {
            sendChatNotification(error.response.data.error.message, 'error')
          }
        })
    }
    const onChangeUserStatus = statusData => {
      // dataToUpdate = { status: statusData }
      useChat.updateChatUserProfileStatus(statusData)
        .then(response => {
          const { chatUserProfile } = response.data
          if (chatUserProfile) {
            chatUserProfileLocal.value = chatUserProfile
          }
          sendChatNotification('Updated !!', 'success')
        })
        .catch(error => {
          // Roolback ui
          chatUserProfileLocal.value.status = props.chatUserProfile.status

          if (error.response && error.response.data && error.response.data.error) {
            sendChatNotification(error.response.data.error.message, 'error')
          }
        })
    }

    return {
      // UI User status
      resolveUserStatus,
      avatarText,

      // User actions
      chatUserProfileLocal,
      userStatusOptions,
      onChangeUserStatus,
      onChangeUserAbout,

      // Icons
      icons: {
        mdiClose,
        mdiBellOutline,
      },
    }
  },
}
</script>

<style>
</style>
