<template>
  <div
    class="chat-contact d-flex align-center pa-3 cursor-pointer rounded-lg"
    :class="{'bg-gradient-primary active-chat-contact': isActive}"
    v-on="$listeners"
  >
    <v-badge
      bottom
      overlap
      dot
      bordered
      :color="resolveUserStatus(contact.userContact.status).color"
      offset-x="11"
      offset-y="11"
      class="me-3 user-status-badge"
      :value="isChatContact"
    >
      <v-avatar
        size="38"
        class="v-avatar-light-bg"
        :class="[{'bg-static-white': isChatContact && isActive}, `${resolveUserStatus(contact.userContact.status).color}--text`]"
      >
        <v-img
          v-if="getUserAvatar(contact.userContact)"
          :src="getUserAvatar(contact.userContact)"
        ></v-img>
        <span v-else>{{ avatarText(getUserName(contact.userContact)) }}</span>
      </v-avatar>
    </v-badge>
    <div class="flex-grow-1 overflow-hidden">
      <p class="mb-0 font-weight-medium text--primary">
        {{ getUserName(contact.userContact) }}
      </p>
      <!-- lastMessage (Optional - Puede estar el chat vacio) -->
      <p class="text--disabled mb-0 text-truncate">
        {{ isChatContact ? (chatRoomUser.lastMessage ? chatRoomUser.lastMessage.message : '--') : getUserAbout(contact.userContact) }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta d-flex flex-column align-self-stretch align-end"
    >
      <p
        v-if="chatRoomUser.lastMessage"
        class="text-no-wrap mb-0"
      >
        {{ formatDateToMonthShort(chatRoomUser.lastMessage.createdAt, { hour: 'numeric', minute: 'numeric' }) }}
      </p>
      <v-badge
        v-if="chatRoomUser.unseenMsgs"
        color="error"
        inline
        :content="chatRoomUser.unseenMsgs"
        class="number-badge"
      >
      </v-badge>
    </div>
  </div>
</template>

<script>
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'

// Services
import useChatHandler from '../useChatHandler'

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    chatRoomUser: {
      type: Object,
      required: false,
      default: null,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { resolveUserStatus } = useChatHandler()

    // Contact
    const getUserAvatar = user => user?.avatar || ''
    const getUserStatus = user => user?.status || ''
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getUserAbout = user => user?.about || ''
    const getUserCompanyRole = user => user?.companyRole || ''

    // const getUserLastMessage = contact => contact.

    // Company
    const getCompanyName = company => company?.general?.name || ''

    return {
      // UI
      resolveUserStatus,
      formatDateToMonthShort,
      avatarText,

      // Contact
      getUserAvatar,
      getUserName,
      getUserStatus,
      getUserAbout,
      getUserCompanyRole,

      // Company
      getCompanyName,
    }
  },
}
</script>

<style>
</style>
