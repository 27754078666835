<template>
  <v-card class="max-h-content-container h-full-- app-chat rounded-0 position-relative overflow-hidden text-sm">
    <!-- Left Sidebar: Contacts and Chats -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : sidebarWidthLeft"
      touchless
      :right="$vuetify.rtl"
      mobile-breakpoint="xs"
      :temporary="$vuetify.breakpoint.xsOnly"
      absolute
    >
      <!-- Loading indicator -->
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        class="position-absolute"
      ></v-progress-linear>
      <!-- <v-divider></v-divider> -->

      <!-- Wait until first load -->
      <ChatLeftSidebarContent
        v-if="chatUserProfile"
        v-model="isLeftSidebarOpen"
        :chat-user-profile="chatUserProfile"
        :chat-room-users="chatRoomUsers"
        :contacts="contacts"
        :active-chat-contact-id="(activeChatRoomUser && activeChatRoomUser.contact) ? activeChatRoomUser.contact.id : null"
        @open-chat="(contact) => openChatRoomUser(contact)"
        @open-chat-user-profile-sidebar="isUserProfileSidebarOpen = true"
      ></ChatLeftSidebarContent>
    </v-navigation-drawer>

    <!-- Left Sidebar: User Profile(Me) -->
    <v-navigation-drawer
      v-model="isUserProfileSidebarOpen"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : sidebarWidthLeft"
      touchless
      :right="$vuetify.rtl"
      mobile-breakpoint="xs"
      temporary
      absolute
    >
      <ChatUserProfileSidebarContent
        v-model="isUserProfileSidebarOpen"
        :chat-user-profile="chatUserProfile"
        @on-close="onCloseChatUserPrifileSidebar()"
      ></ChatUserProfileSidebarContent>
    </v-navigation-drawer>

    <!-- Right Sidebar: Contact Profile -->
    <v-navigation-drawer
      v-if="activeChatRoomUser && activeChatRoomUser.contact"
      v-model="isActiveChatUserProfileSidebarOpen"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : sidebarWidthRight"
      mobile-breakpoint="xs"
      absolute
      :temporary="!$vuetify.breakpoint.mdAndUp"
      :right="!$vuetify.rtl"
      touchless
    >
      <ContactSidebarContent
        v-model="isActiveChatUserProfileSidebarOpen"
        :contact="activeChatRoomUser.contact"
        @on-action-star="/*(item) => setContactStaredToggle(item.id)*/"
        @on-action-delete="(item) => askContactDelete(item.id)"
        @on-action-block="/* (item) => askContactBlock(item.id) */"
      ></ContactSidebarContent>
    </v-navigation-drawer>

    <!-- Delete -->
    <DialogDelete
      :id="contactDeleteId"
      v-model="isContactDeleteDialogActive"
      :title="$t('ContactList.table.dialog_delete.title')"
      :text="$t('ContactList.table.dialog_delete.text')"
      :button="$t('ContactList.table.dialog_delete.button_text')"
      @delete="onContactDelete"
    ></DialogDelete>

    <!-- Content Area -->
    <div
      class="h-full d-flex flex-column"
      :style="contentStyles"
      :class="activeChatRoomUser && activeChatRoomUser.contact ? '' : 'mr-0'"
    >
      <v-progress-linear
        v-if="loadingChat"
        indeterminate
        color="primary"
        class="position-absolute"
      ></v-progress-linear>
      <!-- Active Chat -->
      <div
        v-if="activeChatRoomUser && activeChatRoomUser.contact"
        class="h-full d-flex flex-column"
      >
        <!-- Header: Navbar -->
        <div
          class="d-flex align-center justify-space-between px-2 py-4"
          style="height:4rem"
        >
          <!-- Sidebar Toggler, Avatar & Name -->
          <div class="d-flex align-center">
            <!-- Togle left sidebar -->
            <v-btn
              icon
              class="me-0 d-inline-block d-sm-none"
            >
              <v-icon
                v-show="!isLeftSidebarOpen"
                @click="isLeftSidebarOpen = true"
              >
                {{ icons.mdiArrowLeft }}
              </v-icon>
            </v-btn>
            <div
              class="d-flex align-center"
              @click="isActiveChatUserProfileSidebarOpen = true"
            >
              <v-badge
                bottom
                overlap
                dot
                bordered
                :color="resolveUserStatus(getUserStatus(activeChatRoomUser.contact.userContact)).color"
                offset-x="11"
                offset-y="11"
                class="me-3 user-status-badge"
              >
                <v-avatar
                  size="38"
                  class="cursor-pointer"
                  :class="`v-avatar-light-bg ${resolveUserStatus(getUserStatus(activeChatRoomUser.contact.userContact)).color}--text`"
                >
                  <v-img
                    v-if="getUserAvatar(activeChatRoomUser.contact.userContact)"
                    :src="getUserAvatar(activeChatRoomUser.contact.userContact)"
                  ></v-img>
                  <span v-else>{{ avatarText(getUserName(activeChatRoomUser.contact.userContact)) }}</span>
                </v-avatar>
              </v-badge>
              <div
                class="d-flex flex-column"
                style="min-width: 6.6667rem"
              >
                <p class="mb-0 text--primary font-weight-medium">
                  {{ getUserName(activeChatRoomUser.contact.userContact) }}
                </p>
                <span class="text--disabled text-xs">
                  {{ getCompanyName(activeChatRoomUser.contact.company) }}
                  ({{ getUserCompanyRole(activeChatRoomUser.contact.userContact) }})
                </span>
              </div>
            </div>
          </div>

          <!-- Active Chat Actions: Phone -->
          <div class="d-flex align-baseline">
            <!-- TODO: Search (Hide) -->
            <!-- <v-icon class="me-3 cursor-pointer d-none d-sm-inline-flex">
              {{ icons.mdiMagnify }}
            </v-icon> -->

            <!-- Out if big screen -->
            <div
              v-if="!$vuetify.breakpoint.xsOnly"
              class="w-full d-flex align-center justify-space-between"
            >
              <!-- Phone -->
              <v-btn
                v-if="menuItemPhoneContact.href"
                :disabled="!menuItemMailContact.href"
                color="primary"
                class="cursor-pointer d-sm-inline-flex- me-3-"
                icon
                :href="menuItemPhoneContact.href"
                :target="menuItemPhoneContact.target"
              >
                <v-icon>{{ menuItemPhoneContact.icon }}</v-icon>
              </v-btn>

              <!-- Whatsapp -->
              <v-btn
                v-if="menuItemWhatsappContact.href"
                :disabled="!menuItemWhatsappContact.href"
                color="primary"
                class="cursor-pointer d-sm-inline-flex- me-3-"
                icon
                :href="menuItemWhatsappContact.href"
                :target="menuItemWhatsappContact.target"
              >
                <v-icon>{{ menuItemWhatsappContact.icon }}</v-icon>
              </v-btn>

              <!-- Mail -->
              <v-btn
                v-if="menuItemMailContact.href"
                :disabled="!menuItemMailContact.href"
                color="primary"
                class="cursor-pointer d-sm-inline-flex- me-3-"
                icon
                :href="menuItemMailContact.href"
                :target="menuItemMailContact.target"
              >
                <v-icon>{{ menuItemMailContact.icon }}</v-icon>
              </v-btn>
            </div>

            <!-- More Button: Delete and block -->
            <v-menu
              offset-y
              bottom
              left
              max-width="300"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <!-- Contact options: Mail + whatsapp + phone -->
                <v-list-item v-if="$vuetify.breakpoint.xsOnly">
                  <div class="w-full d-flex align-center justify-space-between">
                    <!-- Phone -->
                    <v-btn
                      v-if="menuItemPhoneContact.href"
                      :disabled="!menuItemMailContact.href"
                      color="primary"
                      class="cursor-pointer"
                      icon
                      :href="menuItemPhoneContact.href"
                      :target="menuItemPhoneContact.target"
                    >
                      <v-icon>{{ menuItemPhoneContact.icon }}</v-icon>
                    </v-btn>

                    <!-- Whatsapp -->
                    <v-btn
                      v-if="menuItemWhatsappContact.href"
                      :disabled="!menuItemPhoneContact.href"
                      color="primary"
                      class="cursor-pointer"
                      icon
                      :href="menuItemWhatsappContact.href"
                      :target="menuItemWhatsappContact.target"
                    >
                      <v-icon>{{ menuItemWhatsappContact.icon }}</v-icon>
                    </v-btn>

                    <!-- Mail -->
                    <v-btn
                      v-if="menuItemMailContact.href"
                      :disabled="!menuItemMailContact.href"
                      color="primary"
                      class="cursor-pointer"
                      icon
                      :href="menuItemMailContact.href"
                      :target="menuItemMailContact.target"
                    >
                      <v-icon>{{ menuItemMailContact.icon }}</v-icon>
                    </v-btn>
                  </div>
                </v-list-item>
                <v-divider
                  v-if="$vuetify.breakpoint.xsOnly"
                  class="my-2"
                ></v-divider>

                <!-- ChatRoomUser Clear -->
                <v-list-item @click="onChatRoomUserClear(activeChatRoomUser.id)">
                  <v-list-item-icon>
                    <v-icon>
                      {{ activeChatRoomUser.lastMessageClear ? icons.mdiEye : icons.mdiEyeOff }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pl-2">
                    <v-list-item-title>{{ activeChatRoomUser.lastMessageClear ? 'Show old messages' : 'Hide messages' }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- Contact Important ? -->
                <v-list-item disabled>
                  <v-list-item-icon>
                    <v-icon>
                      {{ icons.mdiStarOutline }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pl-2">
                    <v-list-item-title> Important contact</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="my-2"></v-divider>
                <!-- Contact Delete -->
                <v-list-item @click="askContactDelete(activeChatRoomUser.contact.id)">
                  <v-list-item-icon>
                    <v-icon color="error">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pl-2">
                    <v-list-item-title> Delete contact</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- Contact Block -->
                <v-list-item :disabled="true">
                  <v-list-item-icon>
                    <v-icon> {{ icons.mdiBlockHelper }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pl-2">
                    <v-list-item-title> Block contact</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <!-- Body: ChatLog scroll -->
        <v-divider></v-divider>
        <perfect-scrollbar
          ref="refChatLogPS"
          :options="perfectScrollbarOptions"
          class="ps-chat-log white"
        >
          <ChatLog
            :chat-user-profile="chatUserProfile"
            :chat-room-user="activeChatRoomUser"
            :last-message-send="lastMessageSend"
            :last-message-received="lastMessageReceived"
            @scroll-to-bottom="scrollToBottomInChatLog()"
          ></ChatLog>
        </perfect-scrollbar>

        <!-- Footer:  -->
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <div class="white">
          <v-form
            autocomplete="off"
            @submit.prevent="sendMessage"
          >
            <!-- height="50" -->
            <!-- ref="refTextarea" -->
            <v-textarea
              :key="activeChatRoomUser.contact.id"
              v-model="chatInputMessage"
              placeholder="Type your message"
              solo
              class="px-4 py-3 flex-grow-0 msg-input-2 rounded-xl"
              hide-details
              auto-grow
              rows="1"
              outlined
              shaped
              @keydown.enter.exact.prevent="() => sendMessage()"
              @keydown.enter.shift.exact.prevent="chatInputMessage += '\n'"
            >
              <template #append>
                <v-btn
                  color="primary"
                  elevation="0"
                  type="submit"
                  :icon="$vuetify.breakpoint.xsOnly"
                  class="rounded-xl"
                  outlined
                >
                  <template v-if="$vuetify.breakpoint.smAndUp">
                    Send
                  </template>
                  <template v-else>
                    <v-icon>
                      {{ icons.mdiSendOutline }}
                    </v-icon>
                  </template>
                </v-btn>
              </template>
            </v-textarea>
          </v-form>
        </div>
      </div>

      <!-- Start Conversation -->
      <div
        v-else
        class="d-flex align-center justify-center flex-grow-1 flex-column"
      >
        <v-avatar
          size="109"
          class="elevation-3 mb-6 bg-card"
        >
          <v-icon
            size="50"
            class="rounded-0 text--primary"
          >
            {{ icons.mdiMessageTextOutline }}
          </v-icon>
        </v-avatar>
        <p
          class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card"
          :class="[{ 'cursor-pointer': $vuetify.breakpoint.smAndDown }]"
          @click="startConversation"
        >
          Start Conversation
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui2'
// eslint-disable-next-line object-curly-newline
import {
  mdiMenu,
  mdiArrowLeft,
  mdiMessageTextOutline,
  mdiMessageOutline,
  mdiPhone,
  mdiWhatsapp,
  mdiEmailOutline,
  mdiMagnify,
  mdiDotsVertical,
  mdiStarOutline,
  mdiEye,
  mdiEyeOff,
  mdiMicrophone,
  mdiSendOutline,
  mdiDeleteOutline,
  mdiBlockHelper,
} from '@mdi/js'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import { until, invoke } from '@vueuse/core'
import { getVuetify, useRouter } from '@core/utils'

// import chatStoreModule from './chatStoreModule'

// UI
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

// Services
import useChat from '@/services/api/modules/chat'

// Services
import useChatHandler from '../useChatHandler'

// Components
import DialogDelete from '@/components/DialogDelete.vue'

// Local Components
import ChatLeftSidebarContent from './ChatLeftSidebarContent.vue'
import ChatLog from './ChatLogWatch.vue'

// Sidebars UserProfile and ContactProfile
import ChatUserProfileSidebarContent from './ChatUserProfileSidebarContent.vue'
import ContactSidebarContent from '../contact-list/ContactSidebarContent.vue'
import useContact from '@/services/api/modules/contact'
import useContactHandler from '../useContactHandler'

export default {
  components: {
    PerfectScrollbar,

    // Local
    ChatUserProfileSidebarContent,
    ChatLeftSidebarContent,
    ChatLog,
    ContactSidebarContent,

    DialogDelete,
  },
  setup() {
    // * Store module Registration
    // const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    // if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)
    // onUnmounted(() => {
    //   if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    // })

    onUnmounted(() => {
      // eslint-disable-next-line no-use-before-define
      if (serviceWorkerActive) {
        // eslint-disable-next-line no-use-before-define
        serviceWorkerActive.postMessage({
          type: 'CLOSE_PORT_CHAT',
        })
      }
    })

    const { sendContactNotification } = useContactHandler()

    // ————————————————————————————————————
    //* ——— Router
    // ————————————————————————————————————
    const { route } = useRouter()

    const $vuetify = getVuetify()
    const sidebarWidthLeft = ($vuetify.breakpoint.width > 600 && $vuetify.breakpoint.width < 700) ? 300 : 374
    const sidebarWidthRight = ($vuetify.breakpoint.mdAndUp) ? 300 : 374
    const {
      isLeftSidebarOpen,
      isRightSidebarOpen: isActiveChatUserProfileSidebarOpen,
      contentStyles,
    } = useResponsiveLeftSidebar(sidebarWidthLeft, sidebarWidthRight)

    // Init as open
    isLeftSidebarOpen.value = true

    // UseChat
    const { addNewChatRoomUser, resolveUserStatus } = useChatHandler()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)

    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value

      // Workarround: Al usar un Watch dentro del ChatLog, el PerfectScroolbar no se actualiza
      refChatLogPS.value.update()

      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // Message handle
    // const onKeydownEnter = () => {
    //   sendMessage()
    // }
    const refForm = ref(null)

    // Service Worker: MessageChannel API: https://felixgerschau.com/how-to-communicate-with-service-workers/
    let serviceWorkerActive = null
    if ('serviceWorker' in navigator) {
      const messageChannel = new MessageChannel()

      navigator.serviceWorker.ready.then(registration => {
        // At this point, a Service Worker is controlling the current page
        serviceWorkerActive = registration.active

        // First we initialize the channel by sending the port to the Service Worker
        //   (this also transfers the ownership of the port)
        serviceWorkerActive.postMessage({
          type: 'INIT_PORT_CHAT',
        }, [messageChannel.port2])

        // Send message test (No se usa)
        // serviceWorkerActive.postMessage({
        //   type: 'INCREASE_COUNT',
        // })
      })

      // Listen to the response: check event.data
      messageChannel.port1.onmessage = event => {
        if (event.data.chatRoomMessage) {
          // eslint-disable-next-line no-use-before-define
          receiveInstantMessage(event.data.chatRoomMessage)
        }
      }
    }

    // ————————————————————————————————————
    //* ——— User Profile Sidebar
    // ————————————————————————————————————
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const chatUserProfile = ref({})
    const isUserProfileSidebarOpen = ref(false)

    const fetchChatUserProfile = () => new Promise(resolve => {
      useChat.fetchChatUserProfile()
        .then(response => {
          const { chatUserProfile: chatUserProfileData } = response.data
          if (chatUserProfileData) {
            chatUserProfile.value = chatUserProfileData
          }
          resolve(response)
        })
    })

    // * One time watch for fetching user profile sidebar data
    invoke(async () => {
      await until(isUserProfileSidebarOpen).toBe(true)

      // store.dispatch('app-chat/getProfileUser').then(response => {
      //   chatUserProfile.value = response.data
      // })
      fetchChatUserProfile()
    })

    // Cuando se cierre el navigation-view, lo volvemos a abrir
    const autoOpenLeftSidebar = async () => {
      await until(isLeftSidebarOpen).toBe(false)
      isLeftSidebarOpen.value = true
    }

    // OnClose: Al cerrar el v-navigation-drawer(User), tambien se cierra el v-navigation-drawer(Contact&Chats)
    //  Lanzamos un observador que espere esta acción para revertir el valor
    const onCloseChatUserPrifileSidebar = () => {
      isUserProfileSidebarOpen.value = false // esto no ya se hace con el v-model pero no está de más

      // Checking...
      //  1.- El sidebar está abierto ? (Lo queremos dejar tal y como está)
      //  2.- Se propaga el cierre de todos los navigation-drawer ?? xsOnly => v-navigation-drawer = { permanent: true}
      if (isLeftSidebarOpen.value && $vuetify.breakpoint.xsOnly) {
        autoOpenLeftSidebar()
      }
    }

    // ————————————————————————————————————
    //* ——— Chats & Contacts
    // ————————————————————————————————————
    const chatRoomUsers = ref([])
    const contacts = ref([])
    const loading = ref(false)

    // const fetchChatAndContacts = () => {
    //   store.dispatch('app-chat/fetchChatsAndContacts').then(response => {
    //     chatRoomUsers.value = response.data.chatRoomUsers
    //     contacts.value = response.data.contacts
    //     profileUserDataMinimal.value = response.data.profileUser
    //   })
    // }
    // fetchChatAndContacts()

    const fetchChatRoomsAndConnections = () => {
      loading.value = true
      useChat.fetchChatsAndContacts()
        .then(response => {
          const {
            contacts: contactList,
            chatRoomUsers: chatRoomUserList,
            chatUserProfile: chatUserProfileData,
          } = response.data
          if (contactList && chatRoomUserList && chatUserProfileData) {
            contacts.value = contactList
            chatRoomUsers.value = chatRoomUserList
            chatUserProfile.value = chatUserProfileData
          }

          // Initialization: Count total chats pendings
          //   let totalChatsUnread = 0
          //   chatRoomUsers.value.forEach(_chatRoomUser => {
          //     totalChatsUnread += (_chatRoomUser.unseenMsgs > 0 ? 1 : 0)
          //   })
          const totalChatsUnread = chatRoomUsers.value.reduce((previousValue, _chatRoomUser) => previousValue + (_chatRoomUser.unseenMsgs > 0 ? 1 : 0), 0)
          store.commit('user/UPDATE_CHAT_ROOM_USERS_PENDING_COUNT', totalChatsUnread)
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchChatRoomsAndConnections()

    // Navegación: Se ha indicado un id (Buscamos en los query params)
    //  Navegación automática -> TODO - Mejor abrir una modal de edición (Por ejemplo ContactRequestInboundComposerDialog)
    const contactIdNavigation = route.value.query.contactId
    const fetchContact = contactId => {
      useContact.fetchContact(contactId)
        .then(response => {
          // eslint-disable-next-line
          openChatRoomUser(response.data.contact)
        })
    }
    if (contactIdNavigation) {
      fetchContact(contactIdNavigation)
    }

    // ————————————————————————————————————
    //* ——— Single Chat
    // ————————————————————————————————————
    const activeChatRoomUser = ref(null)
    const chatInputMessage = ref('')
    const lastMessageSend = ref(null)
    const lastMessageReceived = ref(null)
    const loadingChat = ref(false)

    // Actualizamos las variables de entorno y mantenemos reactividad
    const updateChatRoomUserUI = chatRoomUser => {
      // No indicamos el valor porque el estado de los mensajes viene ya adelantado: Indicamos una copia del mensaje anterior
      // activeChatRoomUser.value = chatRoomUser

      // Unseen chat was read ???
      let unseenChatWasRead = false

      // Set Last Message for active connection
      chatRoomUsers.value.forEach((_chatRoomUser, index) => {
        if (_chatRoomUser.id === chatRoomUser.id) {
          unseenChatWasRead = (_chatRoomUser.unseenMsgs > 0)

          // >>> SFR 12/01/2023 - Añadimos los mensajes nuevos
          // Indicamos una copia del mensaje anterior
          // activeChatRoomUser.value = JSON.parse(JSON.stringify(_chatRoomUser))
          activeChatRoomUser.value = Object.assign(_chatRoomUser, chatRoomUser) // Mantener reactividad

          // Actualizamos LeftSidebar
          // chatRoomUsers.value[index].lastMessage = chatRoomUser.lastMessage
          // chatRoomUsers.value[index].unseenMsgs = 0
          // chatRoomUsers.value[index] = chatRoomUser // No mantiene reactividad
          chatRoomUsers.value[index] = Object.assign(chatRoomUsers.value[index], chatRoomUser) // Mantener reactividad

          // OP2 - El objeto
          // activeChatRoomUser.value = chatRoomUser
          // chatRoomUsers.value[index] = chatRoomUser
          // chatRoomUsers.value[index].unseenMsgs = 0
        }
      })

      // Unseen chat was read ??? ==> Store global
      if (unseenChatWasRead) {
        store.commit('user/UPDATE_CHAT_ROOM_USERS_PENDING_COUNT', store.getters['user/chatRoomUsersPendingCount'] - 1)
      }
    }

    // Open ChatRoom
    const openChatRoomUser = contact => {
      // A diferent chat was selected
      if (activeChatRoomUser.value?.contact?.id !== contact.id) {
        // Reset send message input value
        chatInputMessage.value = ''

        loadingChat.value = true // Loading indicator

        // Return: ChatRoom + Connection
        // store.dispatch('chat/getChat', { contactId })
        useChat.fetchChatByContactId(contact.id)
          .then(response => {
            const { chatRoomUser } = response.data
            if (chatRoomUser) {
              updateChatRoomUserUI(chatRoomUser)
            } else {
              activeChatRoomUser.value = addNewChatRoomUser(contact)
            }

          // Scroll to bottom
          // nextTick(() => { scrollToBottomInChatLog() })
          })
          .finally(() => {
            loadingChat.value = false
          })
      }

      // if smAndDown =>  Close Chat & Contacts left sidebar
      if ($vuetify.breakpoint.xsOnly) isLeftSidebarOpen.value = false
    }

    const createMessagePending = message => {
      const lastMessage = {
        id: null,
        createdAt: new Date(),
        chatRoomId: activeChatRoomUser.value.chatRoom.id,
        message,
        senderId: activeChatRoomUser.value.userId,
        sender: {
          userId: activeChatRoomUser.value.userId,
          avatar: chatUserProfile.value.avatar,
          fullName: chatUserProfile.value.fullName,
          status: chatUserProfile.value.status,
        },
      }

      return lastMessage
    }

    // Se ha recivido un nuevo mensaje
    const receiveInstantMessage = reveivedMessage => {
      // Set Last Message for active connection
      chatRoomUsers.value.forEach((_chatRoomUser, index) => {
        if (_chatRoomUser?.chatRoom.id === reveivedMessage.chatRoomId) {
          chatRoomUsers.value[index].lastMessage = reveivedMessage

          if (_chatRoomUser.id === activeChatRoomUser.value?.id) {
            chatRoomUsers.value[index].unseenMsgs += 1
          }
        }
      })

      lastMessageReceived.value = reveivedMessage
    }

    const sendInstantMessage = simulateMessage => {
      if (activeChatRoomUser.value.id == null) return

      // Set Last Message for active connection
      chatRoomUsers.value.forEach((_chatRoomUser, index) => {
        if (_chatRoomUser.id === activeChatRoomUser.value.id) {
          chatRoomUsers.value[index].lastMessage = simulateMessage

          // chatRoomUsers.value[index] = chatRoomUser // No mantiene reactividad
        //   chatRoomUsers.value[index] = Object.assign(chatRoomUsers.value[index], chatRoomUser) // Mantener reactividad
        }
      })

      // Instant send (Attach this message to the ChatLog)
      lastMessageSend.value = simulateMessage

      // Reset send message input value
      chatInputMessage.value = ''

      // Scroll to bottom
      // nextTick(() => { scrollToBottomInChatLog() }) // No funciona aqui pero si en la promesa
    }

    const sendMessage = () => {
      if (!chatInputMessage.value) return

      useChat.sendChatMessage({
        // chatRoomUserId: activeChatRoomUser.value.id, // Optional
        contactId: activeChatRoomUser.value.contact.id, // Mandatory
        message: chatInputMessage.value, // Mandatory
      })
        .then(response => {
          // TODO: Si recibimos el ChatConnection.class ya arreglamos
          const { chatRoomUser } = response.data
          if (chatRoomUser) {
            // debugger

            // Checking New ???
            if (activeChatRoomUser.value.id === null || activeChatRoomUser.value.id === '') {
              chatRoomUsers.value.push(chatRoomUser)
              activeChatRoomUser.value = chatRoomUser // Update all ChatLog
            } else {
              // Instant send: Ya hemos enviado el mensaje al ChatLog (Evitamos actualizar toda la sala)
              // Actualizar mensaje (Set id)
              lastMessageSend.value = chatRoomUser.lastMessage // Sobreescribimos el mensaje simulado

              // >>> Instant send
              // ChatRoomUser: Set Last Message for active connection
              //   chatRoomUsers.value.forEach((_chatRoomUser, index) => {
              //     if (_chatRoomUser.id === chatRoomUser.id) {
              //       // chatRoomUsers.value[index].lastMessage = chatRoomUser.lastMessage
              //       // chatRoomUsers.value[index] = chatRoomUser // No mantiene reactividad
              //       chatRoomUsers.value[index] = Object.assign(chatRoomUsers.value[index], chatRoomUser) // Mantener reactividad
              //     }
              //   })

              // Update all ChatLog
              // activeChatRoomUser.value = chatRoomUser
              // <<< Instant send
            }

            // >>> Websockets
            // sendMessageWS(JSON.stringify(chatRoomUser.lastMessageRead))

            // Reset send message input value
            // chatInputMessage.value = '' // Instant send

            // Scroll to bottom
            // nextTick(() => { scrollToBottomInChatLog() })
          }
        })

      // Simular envio instantaneo
      if (activeChatRoomUser.value.id !== null) {
        const simulateMessage = createMessagePending(chatInputMessage.value)
        sendInstantMessage(simulateMessage)
      }
    }

    // ————————————————————————————————————
    //* ——— Active Chat
    // ————————————————————————————————————
    // const isActiveChatUserProfileSidebarOpen = ref(false)

    // Contact
    const getUserAvatar = user => user?.avatar || ''
    const getUserStatus = user => user?.status || ''
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getUserAbout = user => user?.about || ''
    const getUserCompanyRole = user => user?.companyRole || ''

    // Company
    const getCompanyName = company => company?.general?.name || ''

    // Open Sidebar in smAndDown when "start conversation" is clicked
    const startConversation = () => {
      // TODO if mdAndUp =>  Nothing
      if ($vuetify.breakpoint.mdAndUp) return
      isLeftSidebarOpen.value = true
    }

    // href: 'mailto:sferrol@empromar.com?subject=FishNet'
    const menuItemMailContact = computed(() => ({
      id: 'mail',
      title: 'Mail',
      icon: mdiEmailOutline,
      href: activeChatRoomUser.value?.contact?.userContact?.email ? `mailto:${activeChatRoomUser.value?.contact?.userContact?.email}` : null,
      target: '',
    }))

    // href: tel:655522118
    const menuItemPhoneContact = computed(() => ({
      id: 'phone',
      title: 'phone',
      icon: mdiPhone,
      href: activeChatRoomUser.value?.contact?.userContact?.phone ? `tel:${activeChatRoomUser.value?.contact?.userContact?.phone}` : null,
      target: '',
    }))

    // https://wa.me/34655522118
    const menuItemWhatsappContact = computed(() => ({
      id: 'phone',
      title: 'whatsapp',
      icon: mdiWhatsapp,
      href: activeChatRoomUser.value?.contact?.userContact?.phone ? `https://wa.me/${activeChatRoomUser.value?.contact?.userContact?.phone}` : null,
      target: '_blank',
    }))

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Delete with dialog ask
    const isContactDeleteDialogActive = ref(false)
    const contactDeleteId = ref('')
    const askContactDelete = contactId => {
      isContactDeleteDialogActive.value = true
      contactDeleteId.value = contactId
    }

    const onContactDelete = contactId => {
      useContact
        .removeContact(contactId)
        .then(() => {
          sendContactNotification('Contact Deleted', 'success')

          // TODO - Update only the current chat
          activeChatRoomUser.value = null
          fetchChatRoomsAndConnections()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            sendContactNotification(error.response.data.error.message, 'error')
          }
        })
    }

    // ChatRoomUserClear with dialog ask
    const isChatRoomUserClearDialogActive = ref(false)
    const chatRoomUserClearId = ref('')
    const askChatRoomUserClear = chatRoomUserId => {
      isChatRoomUserClearDialogActive.value = true
      chatRoomUserClearId.value = chatRoomUserId
    }

    const onChatRoomUserClear = chatRoomUserId => {
      useChat
        .clearChatRoomUser(chatRoomUserId)
        .then(response => {
          sendContactNotification('Contact Cleanned', 'success')
          const { chatRoomUser } = response.data
          if (chatRoomUser) {
            updateChatRoomUserUI(chatRoomUser)
          }
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            sendContactNotification(error.response.data.error.message, 'error')
          }
        })
    }

    return {
      serviceWorkerActive,

      // useChat
      resolveUserStatus,

      // Send message form
      refForm,

      // ChatLog (Scroll utils)
      refChatLogPS,
      scrollToBottomInChatLog,

      // Layout
      isLeftSidebarOpen,
      contentStyles,
      sidebarWidthLeft,
      sidebarWidthRight,

      // User Profile Sidebar
      onCloseChatUserPrifileSidebar,
      isUserProfileSidebarOpen,
      chatUserProfile,

      // Active Chat
      isActiveChatUserProfileSidebarOpen,

      // Chat & Contacts
      chatRoomUsers,
      contacts,
      loading,

      // Single Chat
      activeChatRoomUser,
      chatInputMessage,
      openChatRoomUser,
      sendMessage,
      lastMessageSend,
      lastMessageReceived,
      loadingChat,

      // Contact
      getUserAvatar,
      getUserName,
      getUserStatus,
      getUserAbout,
      getUserCompanyRole,
      menuItemMailContact,
      menuItemPhoneContact,
      menuItemWhatsappContact,

      // Contact CRUD Delete
      isContactDeleteDialogActive,
      contactDeleteId,
      askContactDelete,
      onContactDelete,

      // ChatRoomUserClear
      isChatRoomUserClearDialogActive,
      chatRoomUserClearId,
      askChatRoomUserClear,
      onChatRoomUserClear,

      // Company
      getCompanyName,

      // startConversation
      startConversation,

      // Perfect Scrollbar
      perfectScrollbarOptions,

      // Filter
      avatarText,

      icons: {
        mdiMenu,
        mdiArrowLeft,
        mdiMessageTextOutline,
        mdiMessageOutline,
        mdiPhone,
        mdiEmailOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiStarOutline,
        mdiEye,
        mdiEyeOff,
        mdiMicrophone,
        mdiSendOutline,
        mdiDeleteOutline,
        mdiBlockHelper,
      },
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/preset/preset/apps/chat.scss';
</style>
