<template>
  <div class="chat-log py-3 px-4">
    <!-- class="chat-group d-flex align-start" -->
    <!-- :class="[{'flex-row-reverse': msgGrp.senderId !== chatContactProfileData.userId}, { 'mb-8': formattedChatLogData.length-1 !== index }]" -->
    <div
      v-for="(msgGrp, index) in formattedChatLogData"
      :key="index"
    >
      <div v-if="msgGrp.type === 'day'">
        <div class="d-flex align-center justify-center my-4">
          <div class="chat-content-day align-middle">
            {{ formatDateToMonthShort(new Date(msgGrp.time), false, $i18n.locale) }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="chat-group d-flex align-start"
        :class="[{'flex-row-reverse': msgGrp.senderId !== chatContactProfileData.userId}, { 'mb-8-kk': formattedChatLogData.length-1 !== index }]"
      >
        <!-- <div
          class="chat-avatar"
          :class="msgGrp.senderId !== chatContactProfileData.userId ? 'ms-4' : 'me-4'"
        >
          <v-avatar size="38">
            <v-img :src="msgGrp.senderId === chatContactProfileData.userId ? chatContactProfileData.avatar : chatUserProfileData.avatar"></v-img>
          </v-avatar>
        </div> -->
        <div
          class="chat-body d-inline-flex flex-column"
          :class="msgGrp.senderId !== chatContactProfileData.userId ? 'align-end' : 'align-start'"
        >
          <div
            v-for="(msgData, msgIndex) in msgGrp.messages"
            :key="msgIndex"
            class="chat-content py-3 px-4 elevation-1-no"
            :class="[
              msgGrp.senderId === chatContactProfileData.userId ? 'bg-card chat-left' : 'chat-right',
              msgGrp.messages.length-1 !== msgIndex ? 'mb-2' : 'mb-1'
            ]"
          >
            <span>{{ msgData.msg }}</span>
            <v-icon
              v-if="msgGrp.senderId !== chatContactProfileData.userId"
              class="chat-status"
              size="16"
              :color="resolveFeedbackIcon(msgData.feedback).color"
            >
              {{ resolveFeedbackIcon(msgData.feedback).icon }}
            </v-icon>
            <span
              v-if="msgData.time"
              class="chat-time"
            >
              {{ formatDate(msgData.time, { hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
            </span>
          </div>
          <!-- <div :class="{'text-right': msgGrp.senderId !== chatContactProfileData.userId}">
            <span class="text-xs me-1 text--disabled">{{ formatDate(msgGrp.messages[msgGrp.messages.length-1].time, { hour: 'numeric', minute: 'numeric' }) }}</span>
            <v-icon
              v-if="msgGrp.senderId !== chatContactProfileData.userId"
              size="16"
              :color="resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).color"
            >
              {{ resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).icon }}
            </v-icon>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from '@vue/composition-api'
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'

import { mdiCheck, mdiCheckAll, mdiClock } from '@mdi/js'

export default {
  props: {
    chatRoomUser: {
      type: Object,
      required: true,
    },
    chatUserProfile: {
      type: Object,
      default: () => ({ userId: '', avatar: '' }),
      required: true,
    },
    lastMessageSend: {
      type: Object,
      default: null,
      required: false,
    },
    lastMessageReceived: {
      type: Object,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    // Feedback icon
    const resolveFeedbackIcon = feedback => {
      if (!feedback.isSend) return { icon: mdiClock, color: 'secondary' }
      if (feedback.isSeen) return { icon: mdiCheckAll, color: 'secondary' } // color: 'success'
      if (feedback.isDelivered) return { icon: mdiCheckAll, color: null }

      return { icon: mdiCheck, color: null }
    }

    // Profile User (Right)
    const chatUserProfileData = ref({
      userId: props.chatUserProfile.userId,
      avatar: props.chatUserProfile.avatar,
    })

    // Pofile User Contact (Left)
    const chatContactProfileData = ref({
      userId: props.chatRoomUser.contact.contact.userId,
      avatar: props.chatRoomUser.contact.contact.avatar,
    })

    /* FUNCTIONS FOR FORMATTED CHAT MESSAGES */
    // Crear grupos
    const mapMessageGroup = (senderId, messages = []) => ({
      type: 'message',
      senderId,
      messages,
    })
    const mapMessageGroupDay = time => ({
      type: 'day',
      time,
    })
    const mapMessageFeedback = (id, isSeen = true, isDelivered = true) => ({
      isSend: id !== null,
      isSeen: id !== null && isSeen,
      isDelivered: id !== null && isDelivered,
    })

    // Crear mensajes
    const mapMessage = (id, message, createdAt) => ({
      id,
      type: 'message',
      msg: message,
      time: createdAt,
      feedback: mapMessageFeedback(id),
    })
    const mapMessageNoRead = unseenMsgs => ({
      type: 'no-read',
      msg: `${unseenMsgs} mensajes no leidos`,
      time: null,
    })

    const isSameDay = (date1, date2) => (
      /* eslint-disable operator-linebreak */
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
      /* eslint-enable */
    )

    /* END FUNCTIONS */

    const formattedChatLogData = ref([])

    // Build ChatLog:
    // Antes se generaba con una computada, ahora no porque se pueden añadir mensajes
    // const formattedChatLogData = computed(() => {
    //   // Esta prop es la que lanza el computed
    //   const chatRoomMessageList = props.chatRoomUser.chatRoom?.chatRoomMessages || []
    const buildChatLog = chatRoomMessageList => {
      // debugger
      let formattedChatLog = []
      let currentSenderId = chatRoomMessageList[0] ? chatRoomMessageList[0].senderId : undefined
      let msgGroup = mapMessageGroup(currentSenderId, [])

      let noReadInserted = false
      let currentDate = null

      // Checking... ChatLog exist ??
      if (formattedChatLogData.value.length > 0) {
        // Copiamos todos los mensajes (Eliminar el indicador de NO LEIDO)
        // formattedChatLog = formattedChatLogData.value // Copy al ChatLog
        // Con este MAP eliminamos la marca de NO-LEIDO ()
        formattedChatLog = formattedChatLogData.value.map(_msgGroup => {
          const copyMsgGroup = _msgGroup
          if (copyMsgGroup.type === 'message') {
            copyMsgGroup.messages = copyMsgGroup.messages.filter(msg => msg.type !== 'no-read')
          }

          return copyMsgGroup
        })

        // Checking... Si el ultimo grupo es un mensaje Y es tuyo => lo usamos
        const lastMsgGroup = formattedChatLog[formattedChatLog.length - 1]

        // Indicadores: noReadInserted And currentDate
        noReadInserted = true // Esto ya ha sido resuelto en la primera carga
        // Current date (Date of Last group message)
        if (lastMsgGroup.type === 'message' && lastMsgGroup.messages.length > 0) {
          currentDate = new Date(lastMsgGroup.messages[lastMsgGroup.messages.length - 1].time)
        }

        // Current Group is Mine ==> Use It
        if (lastMsgGroup.type === 'message' && lastMsgGroup.senderId === currentSenderId) {
          msgGroup = formattedChatLog.pop() // Take last and delete (Will insert after processing)
        }
      }

      chatRoomMessageList.forEach((chatRoomMessage, index) => {
        // First Day
        if (!currentDate) {
          currentDate = new Date(chatRoomMessage.createdAt)
          formattedChatLog.push(mapMessageGroupDay(chatRoomMessage.createdAt))

        // Checking... Is diferent day
        } else if (!isSameDay(currentDate, new Date(chatRoomMessage.createdAt))) {
          // Add accumulate messages
          if (msgGroup.type === 'message' && msgGroup.messages.length > 0) {
            formattedChatLog.push(msgGroup)
          }
          msgGroup = mapMessageGroup(currentSenderId, []) // Reset in new day
          // Add new day
          currentDate = new Date(chatRoomMessage.createdAt)
          formattedChatLog.push(mapMessageGroupDay(chatRoomMessage.createdAt))
        }

        // Checking... Unreadmessage
        if (!noReadInserted) {
          // Todos los mensajes están sin leer
          if (!props.chatRoomUser.lastMessageRead) {
            noReadInserted = true
            msgGroup.messages.push(mapMessageNoRead(props.chatRoomUser.unseenMsgs))
          }
        }

        // Caso base
        if (currentSenderId === chatRoomMessage.senderId) {
          msgGroup.messages.push(mapMessage(chatRoomMessage.id, chatRoomMessage.message, chatRoomMessage.createdAt))
        } else {
          // Add accumulate messages
          if (msgGroup.type === 'message' && msgGroup.messages.length > 0) {
            formattedChatLog.push(msgGroup)
          }

          // Change Sender and reset
          currentSenderId = chatRoomMessage.senderId
          msgGroup = mapMessageGroup(currentSenderId, [mapMessage(chatRoomMessage.id, chatRoomMessage.message, chatRoomMessage.createdAt)])
        }

        // At the last record --> Add msgGroup
        if (index === chatRoomMessageList.length - 1) {
          // Add accumulate messages
          if (msgGroup.type === 'message' && msgGroup.messages.length > 0) {
            formattedChatLog.push(msgGroup)
          }

        // Insertamos el aviso despues del ultimo mensaje leido (Excepto si es el ultimo)
        // } else if (!noReadInserted && props.chatRoomUser.lastMessageRead && props.chatRoomUser.lastMessageRead.id === chatRoomMessage.id) {
        //   noReadInserted = true
        //   msgGroup.messages.push(mapMessageNoRead(props.chatRoomUser.unseenMsgs))
        }
      })

      return formattedChatLog
    }

    // Change de ID of last message send:
    //  Instant message was send
    //  When server response, update ID (Take out clock ICON)
    const buildChatLogLastMessage = lastMessageSend => {
      // Vamos a suponer que el mensje está en el ultimo grupo
      const lastMsgGroup = formattedChatLogData.value[formattedChatLogData.value.length - 1]
      lastMsgGroup.messages = lastMsgGroup.messages.map(_msg => {
        const msg = _msg
        if (msg.id == null) {
          msg.id = lastMessageSend.id
          msg.feedback = mapMessageFeedback(msg.id) // Como el feedback depende del ID => Recalculamos
        }

        return msg
      })

    // Option 2: Loop all
    //   formattedChatLogData.value = formattedChatLogData.value.map(_msgGroup => {
    //     const copyMsgGroup = _msgGroup
    //     if (copyMsgGroup.type === 'message') {
    //       copyMsgGroup.messages = copyMsgGroup.messages.map(msg => {
    //         if (msg.id !== null) {
    //           // eslint-disable-next-line no-param-reassign
    //           msg.id = lastMessageSend.id
    //         }
    //         return msg
    //       })
    //     }
    //     return copyMsgGroup
    //   })
    }

    // Reconstruimos todo el ChatLog
    // formattedChatLogData.value = buildChatLog(props.chatRoomUser.chatRoom?.chatRoomMessages || [])
    // nextTick(() => { emit('scroll-to-bottom') })
    watch([
      () => props.chatRoomUser,
      () => props.chatRoomUser.chatRoom, // Observamos cambios en los mensajes
    ], () => {
      // Hacemos reset para que no interprete que son nuevos mensajes...
      formattedChatLogData.value = []
      formattedChatLogData.value = buildChatLog(props.chatRoomUser.chatRoom?.chatRoomMessages || [])

      nextTick(() => { emit('scroll-to-bottom') })
    }, { immediate: true })

    // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
    // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
    // watch(props.lastMessageSend, () => {
    watch(() => props.lastMessageSend, () => {
      if (props.lastMessageSend) {
        if (props.lastMessageSend.id === null) {
          formattedChatLogData.value = buildChatLog([props.lastMessageSend])
        } else {
          buildChatLogLastMessage(props.lastMessageSend)
        }
      }

      nextTick(() => { emit('scroll-to-bottom') })
    })

    // Hemos recibido un mensaje nuevo (Vía Channel)
    watch(() => props.lastMessageReceived, () => {
      if (props.lastMessageReceived) {
        formattedChatLogData.value = buildChatLog([props.lastMessageReceived])
      }

      nextTick(() => { emit('scroll-to-bottom') })
    })

    return {
      chatUserProfileData,
      chatContactProfileData,
      formattedChatLogData,

      // UI Formated
      formatDateToMonthShort,
      formatDate,

      // Feedback Icon
      resolveFeedbackIcon,

      // Icons
      icons: {
        mdiCheck,
        mdiCheckAll,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

    .chat-log {
        // font-family: SF Pro Text, SF Pro Icons, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
        // font-family: Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif;
        // font-family: Helvetica, sans-serif;
        // font-family: sans-serif;
        font-family: Helvetica;
        font-family: Helvetica Neue;
        font-size: 1rem;
        background-color: #fff;
        // color: black !important;
    }
    .chat-left {
      background: white !important;
      color: black !important;
    }
    .chat-right {
      background-color: #eceff1;
      color: black !important;
    }
    .chat-content {
      border-radius: 20px !important;
      border: 1px solid #ECEFF1;
      // white-space: pre-line;

      white-space: pre-wrap;
      word-break: break-word;
      hyphens: auto;
    }
    .chat-content-no-read {
        float: unset !important;
        margin: auto !important;       // para centrar
        margin-bottom: 8px !important; // para dejar espacio abajo
        padding: 0.3rem 1rem !important;
        border-radius: 2rem !important;
        background: white !important;
        color: black !important;
        text-align: center !important;
    }
    .chat-content-day {
        // padding: 5px 12px 6px;
        // text-align: center;
        // text-shadow: 0 1px 0 rgba(255,255,255,.4);
        // background-color: rgba(255,255,255,.95);
        // border-radius: 7.5px;
        // box-shadow: 0 1px 0.5px rgba(11,20,26,.13);

        // margin: auto; // Center div

        display: inline-flex;
        text-align: center;
        padding: 4px 20px;
        background-color: #cfd8dc;
        border-radius: 20px;
        margin: 12px 0;
        color: #fff;
    }

    // Ajustamos la hora
    .chat-time {
        float: right;
        font-size: .625rem;
        white-space: pre;
        margin: 0 1px 0 8px;
        color: #90a4ae;
        position: relative;
        bottom: -2px;
    }
    .chat-status {
      float: right;
      bottom: -4px;
    }
</style>
